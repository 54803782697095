//export const BASE_URL = "https://api.yumacare.com/api/";
export const BASE_URL = "https://apitest.yumacare.com/api/";

export const ApiRoutes = {
  home: {
    getOperation: `Controller/GetOperationsAnonymous`,
    contact: "Login/AddContact",
  },
  auth: {
    user: {
      login: `Login/Login`,
      register: `Controller/SignUpUser`,
      getAccount: `Controller/GetAcount`,
      userDoctors: "Controller/UserDoctors",
      calendar: "Controller/GetUserCalender",
      addFlight: "Controller/AddUserFlight",
      addHotel: "Controller/AddUserHotel",
      addFlightFile: "Controller/AddUserFlightFiles",
      addHotelFile: "Controller/AddUserHotelFiles",
      sendFeedback: "Controller/FeedBack",
      getProfile: "Controller/GetProfile",
      editProfile: "Controller/EditProfile",
      passwordChange: "Controller/PasswordChange",
      updateProfilePhoto: "Controller/PictureAdd",
      passwordRemember: "Controller/PasswordRemember",
      passwordRememberChange: "Controller/PasswordRememberChange",
    },
    doctor: {
      guid: "Controller/GetDoctorGuid",
      addAward: "Controller/AddDoctorAwards",
      addReference: "Controller/AddDoctorReferences",
      addCv: "Controller/AddDoctorCV",
      addOperation: "Controller/AddDoctorOperations",
      addFile: "Controller/DoctorFilesAdd",
      removeFile: "Controller/DoctorFilesRemove",
    },
    hospital: {
      guid: "Controller/GetHospitalGuid",
      addAward: "Controller/AddHospitalAwards",
      addReference: "Controller/AddHospitalReferences",
      addCv: "Controller/AddHospitalCV",
      addOperation: "Controller/AddHospitalOperations",
      addFile: "Controller/HospitalFilesAdd",
    },
    operation: {
      getUserOperationContact: "Controller/GetUserOperationContact",
      getUserDoctorOperationList: "Controller/GetUserDoctorOperationList",
      addOperation: "Controller/AddUserOperation",
      addOperationFile: "Controller/AddUserOperationFiles",
      getOperationList: "Controller/GetUserOperations",
      getOperationDetail: "Controller/GetUserOperationsById",
    },
  },
  search: {
    category: {
      get: "Controller/GetCategories",
    },
    operation: {
      getByCategory: "Controller/GetOperationsByCategory",
      getByCategories: "Controller/GetOperationsByCategories",
    },
    doctor: {
      get: "Controller/SearchDoctorString",
      byOperation: "Controller/SearchDoctorOperations",
      filterDoctor: "Controller/FilterDoctor",
      landingDoctor: "Controller/LandingDoctor",
    },
    city: "Controller/GetCities",
  },

  doctor: {
    get: "Controller/SearchGetDoctor",
  },
  message: {
    addList: "Controller/AddMessageList",
    getList: "Controller/GetMessageList",
    getDetail: "Controller/GetMessageDetail",
    sendMessage: "Controller/AddMessageDetail",
  },
};
